<template>
	<el-dialog title="名师列表信息" :visible.sync="showVisible" width="750px" center :close-on-click-modal="false">
		<Table
			:table-data="tableData"
			:table-head="tableHead"
			:is-show-selection="false"
			:isShowPage="false"
			:showOperation="false"
			:tableHeight="tableHeight"
		>
		</Table>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			showVisible: false,
			tableHead: [
				{
					label: '姓名',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '专业',
					prop: 'major',
					formatter: (row) => {
						return row.major || '-';
					},
				},
				{
					label: '头衔',
					prop: 'honor',
					formatter: (row) => {
						return row.honor || '-';
					},
				},
				{
					label: '擅长',
					prop: 'forte',
					formatter: (row) => {
						return row.forte || '-';
					},
				},
			],
			tableData: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .el-table {
	margin-bottom: 20px;
}
</style>

